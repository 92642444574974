// @flow
import { createSelector } from "reselect";
import get from "lodash/get";
import { find, isEmpty, last } from "lodash";
import { isMobile } from "../../../utils";
import { getActualRound, getRoundsById } from "../../selectors/rounds";
import isAuthenticated from "../../../utils/auth/isAuthenticated";
import { IS_OFFSEASON } from "../../constants";
// import { getDefaultDraftLeagueRoute } from '../../../helpers/league';
import type { TPLeague } from "../../types";
import { isAllTrue, isAnyTrue, isRound7Started } from "../../../helpers";
import getTeamsById from "../leaguesDraft/getTeamsById";
import {
	selectedDraftLeagueSelector,
	selectedClassicLeagueSelector,
	isDraftSelector,
	isClassicSelector,
	pathnameSelector
} from "./getSelectedLeague";



const userIdSelector = (state, props: Object) => get(state, "user.data.id");
const userTeamNameSelector = state => get(state, "user.data.team_name", "");
const selectedClassicLeagueIdSelector = state => state.uiSelectedLeague.selected_classic;
const getLeagueName = league => get(league, "name", "") || "About League";
const draftStatus = (league: TPLeague) => {
	
	return ((league.status === "scheduled"
		&& (Date.parse(league.draft_start) - Date.parse(Date())) > 1800000));
};



// eslint-disable-next-line complexity
const getAboutLeagueItems = (
	league: TPLeague, rounds_by_id: Object, isTeamsEmptybutCommissioner
) => {
	const status = draftStatus(league);
	const rounds = Object.keys(rounds_by_id).map(roundId => rounds_by_id[roundId]);
	const actual_round = find(rounds, ({ status, id }) => 
		["active", "scheduled"].includes(status) && id > 0)
	|| last(rounds);
	const isUFRegen = Boolean(get(league,"uf_regenerated_from",0));
	const has_subscription = isAnyTrue([
		["full", "lite"].includes(league.subscription),
		isUFRegen
	]);
	const preDraftItems = (isAnyTrue([Boolean(status), league.draft_status === "scheduled"]) ) ? [
		{
			to: `/draft/league/${league.id}/draft-order`,
			title: "Manage Draft Order"
		},
	] : [];
	if(isAllTrue([has_subscription, league.draft_status === "scheduled"]) ){
		preDraftItems.push({
			to: `/draft/league/${league.id}/edit-picks`,
			title: "Edit draft picks"
		});
	}
	const teamToTeamSwapMenu = isAnyTrue([
		isMobile(),
		league.draft_status !== "complete"
	])  ? 
		[]:[{
			to: `/draft/league/${league.id}/team-swap`,
			title: "Team to Team Swap",
		}];
	const sub_items = [
		{
			to: `/draft/league/${league.id}/settings`,
			title: "Update Settings"
		},
		{
			to: `/draft/league/${league.id}/team-name`,
			title: "Edit Team Name"
		},
		{
			to: `/draft/league/${league.id}/invite`,
			title: "Invite"
		},
		{
			to: `/draft/league/${league.id}/teams`,
			title: "Manage Teams",
		},
		...teamToTeamSwapMenu,
		...preDraftItems
	];
	if((isAnyTrue([isAllTrue([status, league.privacy === 0]), 
		league.draft_status !== "complete"]) )){
		sub_items.push({
			to: `/draft/league/${league.id}/remove-league`,
			title: "Remove League"
		});
	}
	const isSubscriptionAndDraftComplete = isAllTrue([
		["full", "lite"].includes(league.subscription),
		league.draft_status === "complete",
	]);
	const isRound7Start = isRound7Started(actual_round);
	const { start_round } = league;
	const isFirstRoundFinished = 
			get(rounds_by_id, `${start_round}.status`, "scheduled") 
				=== "complete";
	if(isSubscriptionAndDraftComplete){
		sub_items.push({
			to: `/draft/league/${league.id}/edit-teams`,
			title: "Edit Teams",
		});
	}
	if(isAllTrue([
		isFirstRoundFinished,
		isAnyTrue([
			isSubscriptionAndDraftComplete,
			!isRound7Start
		])
	]) ){
		if(isFirstRoundFinished ){
			sub_items.push({
				to: `/draft/league/${league.id}/edit-scores`,
				title: "Edit Scores"
			});
		}
	}
	if(isTeamsEmptybutCommissioner){

		return sub_items.filter(item => item.title !== "Edit Team Name");
	}

	return sub_items;
};

type SubItem = {
	to: string,
	title: string,
};

const pushLeagueOverviewSubItems = (
	sub_items: SubItem[], 
	selected_league: TPLeague, 
	league_id: number
) => {
	if (selected_league && selected_league.draft_status === "complete") {
		sub_items.push({
			to: `/draft/league/${league_id}/transactions`,
			title: "Transactions"
		});

		sub_items.push({
			to: `/draft/league/${league_id}/history`,
			title: "Draft Recap"
		});
		sub_items.push({
			to: `/draft/league/${league_id}/team-breakdowns`,
			title: "Team Breakdowns "
		});
	}
};

const getDraftNav = createSelector(
	pathnameSelector,
	userIdSelector,
	selectedDraftLeagueSelector,
	getRoundsById,
	getTeamsById,
	// eslint-disable-next-line complexity
	(
		pathname,
		user_id,
		selected_draft_league,
		rounds_by_id,
		teams_by_id,
	) => {
		const nav_object = [];
		const isTeamsEmptybutCommissioner = isAllTrue([
			selected_draft_league.commissioner === user_id, isEmpty(teams_by_id)
		]);
		const { id: league_id } = selected_draft_league;

		const league_overview_sub_items = [
			{
				to: `/draft/league/${league_id}/fixtures`,
				title: "Fixtures"
			},
			{
				to: `/draft/league/${league_id}/ladder`,
				title: "Ladder"
			}
		];

		pushLeagueOverviewSubItems(league_overview_sub_items, selected_draft_league, league_id);
		
		nav_object.push({
			to: `/draft/league/${league_id}/`,
			title: "League Overview",
			sub_title: get(selected_draft_league, "name", "Overview"),
			sub_items: league_overview_sub_items
		});
		if(!isTeamsEmptybutCommissioner){
			nav_object.push({
				to: getDraftUrl(selected_draft_league),
				title: "My Team",
			});
		}
		

		nav_object.push({
			to: `/draft/league/${league_id}/afl-matchups/`,
			title: "Match Centre",
			sub_title: league_id ? "Match Centre" : undefined,
			sub_items: league_id 
				? [
					{
						to: `/draft/league/${league_id}/fantasy-matchups`,
						title: "League Matchups"
					},
					{
						to: `/draft/league/${league_id}/afl-matchups`,
						title: "AFL Matchups"
					},
				] : undefined
		});

		const players_sub_items =  [
			{
				to: `/draft/league/${league_id}/players/team-to-team-trade`,
				title: "Team to Team Trade"
			},
			{
				to: `/draft/league/${league_id}/players/free-agent`,
				title: "Free Agents"
			},
			{
				to: `/draft/league/${league_id}/players/compare/`,
				title: "Compare players"
			}
		];


		if (isAllTrue([
			selected_draft_league, 
			selected_draft_league.trade_waivers, 
			selected_draft_league.draft_status === "complete"
		])  
		) {
			players_sub_items.splice(2, 0, {
				to: `/draft/league/${league_id}/players/rfa`,
				title: "Restricted Free Agents"
			});
		}

		if (isAllTrue([selected_draft_league, selected_draft_league.draft_status !== "complete"])) {
			players_sub_items.shift();
			players_sub_items.shift();
		}
		if(!isTeamsEmptybutCommissioner){
			nav_object.push({
				to: `/draft/league/${league_id}/players`,
				title: "Players",
				sub_title: "Players",
				sub_items: players_sub_items
			});
		}

		

		const privacy = get(selected_draft_league, "privacy", 0);
		const isCommissioner = user_id === get(selected_draft_league, "commissioner");
		const viewDraftOrder = selected_draft_league.draft_status === "scheduled" 
			&& !isCommissioner ? [{
				to: `/draft/league/${league_id}/draft-order`,
				title: "View Draft Order"
			}]:[];

		const league_about_sub_items = isCommissioner?
			{
				sub_title: getLeagueName(selected_draft_league),
				sub_items: getAboutLeagueItems(

					selected_draft_league, rounds_by_id, isTeamsEmptybutCommissioner
				)
			} : privacy ? {
				sub_title: getLeagueName(selected_draft_league),
				sub_items: [
					{
						to: `/draft/league/${league_id}/about`,
						title: "View settings"
					},
					{
						to: `/draft/league/${league_id}/team-name`,
						title: "Edit Team Name"
					},
					{
						to: `/draft/league/${league_id}/invite`,
						title: "Invite"
					},
				
					{
						to: `/draft/league/${league_id}/teams`,
						title: "View Teams",
					}
				]
			} : {
				sub_title: getLeagueName(selected_draft_league),
				sub_items: [
					{
						to: `/draft/league/${league_id}/about`,
						title: "View settings"
					},
					{
						to: `/draft/league/${league_id}/team-name`,
						title: "Edit Team Name"
					},
					
					{
						to: `/draft/league/${league_id}/teams`,
						title: "View Teams",
					},
					
				]
			};

		league_about_sub_items.sub_items.push(...viewDraftOrder);
		nav_object.push({
			to: `/draft/league/${league_id}/about`,
			title: "Settings",
			sub_title: getLeagueName(selected_draft_league),
			...league_about_sub_items,
		});

	
		return nav_object.filter(o => o !== undefined);
	}
);

const addLeagueOverview = (
	nav_object: Object[], 
	selected_classic_league?: Object, 
	selected_classic_league_id?: number
) => {
	if(selected_classic_league_id) {
		const league_overview_sub_items = [
			{
				to: `/classic/league/${selected_classic_league_id}/ladder`,
				title: "Ladder"
			},
		];

		if(get(selected_classic_league, "type") !== "open") {
			league_overview_sub_items.unshift({
				to: `/classic/league/${selected_classic_league_id}/fixtures`,
				title: "Fixtures"
			});
			league_overview_sub_items.push({
				to: `/classic/league/${selected_classic_league_id}/team-breakdowns`,
				title: "Team Breakdowns"
			});
		}

		nav_object.push({
			to: `/classic/league/${selected_classic_league_id}`,
			title: "League Overview",
			sub_title: get(selected_classic_league, "name", "Overview"),
			sub_items: league_overview_sub_items
		});

	}
};

const pushMatchCentre = (nav_object: Object[], league_id: number) => {
	nav_object.push({
		to: "/classic/afl-matchups/",
		title: "Match Centre",
		sub_title: league_id 
			? "Match Centre"
			: undefined,
		sub_items: league_id
			? [
				{
					to: `/classic/league/${league_id}/matchup`,
					title: "League Matchups"
				},
				{
					to: "/classic/afl-matchups/",
					title: "AFL Matchups"
				},
			] : undefined
	});
};

const getClassicNav = createSelector(
	userIdSelector,
	selectedClassicLeagueSelector,
	selectedClassicLeagueIdSelector,
	userTeamNameSelector,
	getActualRound,
	(
		user_id,
		selected_classic_league,
		selected_classic_league_id,
		team_name,
		actual_round,
	) => {

		const nav_object = [];

		const round_one_finished = actual_round.id > 1 
			|| (actual_round.id === 1 && actual_round.status === "complete");

		addLeagueOverview(nav_object, selected_classic_league, selected_classic_league_id);

		const sub_items = round_one_finished
			? [
				{
					to: "/classic/advanced-trades",
					title: "Trade Centre"
				},
				{
					to: "/classic/rankings",
					title: "Rankings"
				}
			]
			: [
				{
					to: "/classic/rankings",
					title: "Rankings"
				}
			];

		nav_object.push({
			to: "/classic/team",
			title: "My Team",
			sub_title: team_name,
			sub_items
		});

		pushMatchCentre(nav_object, selected_classic_league_id);

		nav_object.push({
			to: "/classic/stats-centre",
			title: "Stats Centre",
			sub_title: "Stats Centre",
			sub_items: [
				{
					to: "/classic/stats-centre/compare",
					title: "Compare players"
				}
			]
		});

		if(selected_classic_league_id) {
			const league_id = get(selected_classic_league, "id", 0);
			const privacy = get(selected_classic_league, "privacy", 0);

			const league_about_sub_items = 
				(user_id === get(selected_classic_league, "commissioner")) 
					? {
						sub_title: getLeagueName(selected_classic_league),
						sub_items: [
							{
								to: `/classic/league/${league_id}/settings`,
								title: "Settings"
							},
							{
								to: `/classic/league/${league_id}/invite`,
								title: "Invite"
							},
							{
								to: `/classic/league/${league_id}/teams`,
								title: "Manage Teams"
							}
						]
					}  
					: privacy ? {
						sub_title: getLeagueName(selected_classic_league),
						sub_items: [
							{
								to: `/classic/league/${league_id}/invite`,
								title: "Invite"
							}
						]
					} : {} ;

			nav_object.push({
				to: `/classic/league/${league_id}/about`,
				title: "About League",
				sub_title: getLeagueName(selected_classic_league),
				...league_about_sub_items
			});
		}
		return nav_object.filter(o => o !== undefined);
	}
);



export const getLeftNav = createSelector(
	pathnameSelector,
	isAuthenticated,
	isDraftSelector,
	isClassicSelector,
	getDraftNav,
	getClassicNav,
	(
		pathname,
		is_authorized,
		is_draft,
		is_classic,
		draft_nav,
		classic_nav
	) => {

		const nav_object = [];

		if(!is_authorized && !IS_OFFSEASON ) {
			return [...nav_object, {
				to: "/classic/stats-centre",
				title: "Stats Centre",
				sub_title: "Stats Centre",
				sub_items: [
					{
						to: "/classic/stats-centre/compare",
						title: "Compare players"
					}
				]
			}];
		}
		if(is_authorized){
			nav_object.push({
				to: "/game-selection",
				title: "Game Selection",
				sub_title: "Select which game you want to play?",
				sub_items: [
					{
						to: "/classic",
						title: "Classic"
					},
					{
						to: "http://fantasydraft.afl.com.au/?utm_source=aflfantasy"+
							"&utm_medium=web&utm_id=draft-launch",
						title: "Draft",
						external: true,
						show_new: true,
					},
					{
						to: 
							"https://aflwfantasy.afl/?utm_source=aflfantasy"+
							"&utm_medium=web&utm_id=aflwfantasy-launch",
						title: "AFLW Fantasy",
						external: true,
						show_new: false,
					}
				]
			});
	
		}

		
		// Classic draft items
		if(is_classic) {
			return [
				...nav_object,
				...classic_nav
			].filter(o => o.to);
		}

		// Draft nav iteam
		if(pathname.search(/^\/draft\/league\/[0-9]{1,}/) === 0) {
			return [
				...nav_object,
				...draft_nav
			].filter(o => o.to);
		}
		return nav_object.filter(o => o.to);
	});

// utils
const getDraftUrl = league => {
	const league_id = get(league, "id", "");
	const url_base = `/draft/league/${league_id}/`;

	if(["future", "unscheduled", "scheduled"].includes(get(league, "draft_status"))) {
		return `${url_base}pre-draft`;
	}
	else if(get(league, "draft_status") === "active") {
		return `${url_base}live-draft`;
	}
	return `${url_base}team`;
};