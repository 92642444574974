// @flow
const colors = {
	"12": "#FA6300",
	"13": "#30B702",
	"14": "#6C03A8",
	"15": "#0CC0D4",
	"0": "green",
	"2": "orange",
	"3": "pink",
	"4": "teal",
	"16": "maroon"
};
export const getByeColor = (bye_round: number, default_color?: string = "#fff0") => {
	if (bye_round in colors) {
		return colors[bye_round];
	}
	return default_color;
};

export default getByeColor;