// @flow
import React from "react";
import {connect} from "react-redux";
import {BrowserRouter as Router, Redirect, Route, Switch} from "react-router-dom";

import * as actions from "../modules/actions";
import positions from "../helpers/positions";

import FetchUserIfAuthenicated from "../components/utils/FetchUserIfAuthenicated";
import GtmPageViewDataLayer from "../components/utils/GtmPageViewDataLayer";
import {RedirectToHome} from "../components";
import Header from "../components/Header";
import AppErrorBoundary from "../components/AppErrorBoundary";
// import Footer from '../components/Footer';
import PrivateRoute from "../components/PrivateRoute";
import SetSelectedLeague from "../utils/SetSelectedLeague";
import RedirectLeagueInvite from "../components/RedirectLeagueInvite";
import {isOffSeason} from "../utils";
import GlobalRedirect from "../components/GlobalRedirect";
import { IS_DISABLE_LIVE_DRAFT, IS_HIDE_DRAFT } from "../modules/constants";
import DraftPlayer from "./draft/league/Player";
import Players from "./draft/league/Players";
import Help from "./Help";
// Global pages
// import LoginOld from "./LoginOld";
import Login from "./Login";
// import LoginCallback from './LoginCallback';
import Logout from "./Logout";
import Registration from "./Registration";
import Recover from "./Recover";
import Account from "./Account";
import UpdatePassword from "./UpdatePassword";
import CoachSubscription from "./CoachSubscription";
import CoachSubscriptionSettings from "./CoachSubscriptionSettings";
import ContactUs from "./ContactUs";
import GameSelection from "./GameSelection";
import GameSelectionFavouriteRedirect from "./GameSelectionFavouriteRedirect";
import Communications from "./Communications";
// import SocialConnections from "./SocialConnections";
import ComingSoon from "./ComingSoon";
import NoMatch from "./NoMatch";

// Draft pages
import DraftLeagues from "./draft/leagues/Leagues";
import DraftLeagueCreate from "./draft/leagues/LeagueCreate";
import DraftLeagueCreateUF from "./draft/leagues/LeagueCreateUF";
import DraftLeaguesJoin from "./draft/leagues/LeaguesJoin";
import DraftLeagueOverview from "./draft/league/LeagueOverview";
import LeagueHistory from "./draft/league/LeagueHistory";
import DraftLeagueSettings from "./draft/league/LeagueSettings";
import DraftLeagueEditPicks from "./draft/league/LeagueEditPicks";
import DraftLeagueOrder from "./draft/league/LeagueDraftOrder";
import DraftLeagueTeams from "./draft/league/LeagueTeams";
import DraftLeagueEditTeams from "./draft/league/EditTeams";
import DraftLeagueEditScores from "./draft/league/EditScores";
import DraftLeagueRemove from "./draft/league/LeagueRemove";
import DraftLeagueInvite from "./draft/league/LeagueInvite";
import DraftLeagueFixtures from "./draft/league/LeagueFixtures";
import DraftLeagueLadder from "./draft/league/LeagueLadder";
import TeamSwap from "./draft/league/TeamSwap";
import DraftLeagueTeamBreakdowns from "./draft/league/LeagueTeamBreakdowns";
import LiveDraft from "./draft/league/LiveDraft";
import PreDraft from "./draft/league/PreDraft";
import DraftLeagueAbout from "./draft/league/LeagueAbout";
import DraftTransactions from "./draft/league/Transactions";
import DraftTeam from "./draft/league/LeagueTeam";
import DraftMatchUp from "./draft/league/MatchUp";
import RedirectOnInvalidDraftLeague from "./draft/RedirectOnInvalidDraftLeague";
import DraftMatchCentre from "./draft/league/MatchCentre";
import LeagueNameChange from "./draft/league/LeagueNameChange";

// Classic pages
import MyTeam from "./classic/MyTeam";
import {LeagueOverview as ClassicLeagueOverview} from "./classic/league/LeagueOverview";
import ClassicLeaguesCreate from "./classic/leagues/LeaguesCreate";
import ClassicLeaguesJoin from "./classic/leagues/LeaguesJoin";
import ClassicLeaguesInvite from "./classic/league/Invite";
import ClassicLeagueFixtures from "./classic/league/LeagueFixtures";
import ClassicLeagueLadder from "./classic/league/LeagueLadder";
import ClassicLeagueSettings from "./classic/league/LeagueSettings";
import ClassicLeagueAbout from "./classic/league/LeagueAbout";
import PlayerNews from "./classic/PlayerNews.js";
import TeamOfRound from "./classic/TeamOfRound.js";
import MyClassicLeagues from "./classic/leagues/MyLeagues";
import StatsCentre from "./classic/StatsCentre";
import PlayerClassic from "./classic/Player";
import Rankings from "./classic/Rankings";
import RedirectOnInvalidClassicLeague from "./classic/RedirectOnInvalidClassicLeague";
import AdvancedTrades from "./classic/AdvancedTrades";
import DraftComparePlayers from "./draft/league/ComparePlayer";
import ClassicComparePlayers from "./classic/ComparePlayer";
import ClassicMatchUp from "./classic/league/MatchUp";
import ClassicLeagueMembers from "./classic/league/Members";
import ClassicMatchCentre from "./classic/MatchCentre";
import ClassicLeagueTeamBreakdowns from "./classic/league/LeagueTeamBreakdowns";
import UserTeam from "./classic/UserTeam";
import OffSeasonStatsCentre from "./offSeason/OffSeasonStatsCentre";
import Terms from "./Terms";

import OffSeason from "./OffSeason";
import OffSeasonRankingsPage from "./OffSeasonRankings";
import LoginCallbackPage from "./LoginCallback";
import InitiatePage from "./InitiatePage";
// import AfliDConfirmLinkAccountPage from "./login/aflID/AfliDConfirmLinkAccountPage";
// import AfliDNoMatch from "./login/aflID/AfliDNoMatch";
// import AfliDLinkOld from "./login/aflID/AfliDLinkOld";
import AfliDMiniRego from "./login/aflID/AfliDMiniRego";
import AfliDForgotPassword from "./login/aflID/AfliDForgotPassword";
import AfliDChangePassword from "./login/aflID/AfliDChangePassword";
import AfliDNewUserRego from "./login/aflID/AfliDNewUserRego";
// import { UFPWResetPage } from "./UFPWReset";
import EditAdvancedTrades from "./classic/EditAdvancedTrades";
import PlayerProfileSearch from "./PlayerProfileSearch";


// import Icons from "./Icons";

const DraftMatchUpPath = "/draft/league/:league_id/fantasy-matchups";
const DraftMatchUpPathWithRound = `${DraftMatchUpPath}/:round_id`;
const DraftMatchUpPathDirect = `${DraftMatchUpPath}/:round_id/:home_team_id/:away_team_id`;

const ClassicMatchUpPath = "/classic/league/:league_id/matchup";
const ClassicMatchUpPathWithRound = `${ClassicMatchUpPath}/:round_id`;
const ClassicMatchUpPathDirect = `${ClassicMatchUpPath}/:round_id/:home_team_id/:away_team_id`;

type Props = {
	setPositions: Function;
};

const OFF_SEASON = isOffSeason();


const OffSeasonRoutes = () => <Switch>
	<Route
		exact
		path="/"
		component={OffSeason}
	/>
	<Route
		path="/classic/help/:section"
		exact
		render={props => <Help {...props} is_classic={true} />}
	/>
	<Route
		path="/contact-us"
		component={ContactUs}
		exact
	/>
	<Route path="/player-profile-search" component={PlayerProfileSearch} />
	<Route
		path="/terms-conditions"
		component={Terms}
		exact
	/>
	<Route
		path="/off-season-rankings"
		component={OffSeasonRankingsPage}
		exact
	/>
	<Route
		path="/classic/rankings"
		exact
		component={Rankings}
	/>
	<Route
		path="/off-season/stats-centre"
		component={OffSeasonStatsCentre}
		exact
	/>
	<Route
		path="/classic/player/:player_slug"
		exact
		component={PlayerClassic}
	/>
	<Route component={RedirectToHome} />
</Switch>;
	

const comingSoonDraftPath = 
	"/draft/league/:league_id/matchup/:home_team_id?/:away_team_id?/:round_id?";


const InSeasonRoutes = () => (
	<React.Fragment>
		<Route path="/:game/league/:league_id" component={SetSelectedLeague} />
		
		<Route path="/classic/league/:league_id" component={RedirectOnInvalidClassicLeague} />
		<Route path="/share/:type/:base64" exact component={RedirectLeagueInvite} />
		<Switch>
			{/* Global Routes */}
			<Route path="/landing" render={() => <Redirect to="/" />} />
			<Route path="/" exact component={Login} />
			{/* <Route path="/login-old" exact component={LoginOld} /> */}
			<Route path="/login-new" exact component={Login} />
			<Route path="/login/callback" exact component={LoginCallbackPage} />
			<Route path="/initiate" exact component={InitiatePage} />
			<Route path="/player-profile-search" component={PlayerProfileSearch} />
			{/* <Route
				path="/login/confirm-link-account"
				exact
				component={AfliDConfirmLinkAccountPage}
			/> */}
			{/* <Route path="/login/no-match" exact component={AfliDNoMatch} /> */}
			{/* <Route path="/login/link-old-account" exact component={AfliDLinkOld} /> */}
			<Route path="/login/mini-rego" exact component={AfliDMiniRego} />
			<Route path="/login/new-user-rego" exact component={AfliDNewUserRego} />
			<Route path="/login/forgot-password" exact component={AfliDForgotPassword} />
			<Route path="/logout" exact component={Logout} />
			<Route path="/change-password" exact component={AfliDChangePassword} />
			<Route path="/registration" exact component={Registration} />
			<Route path="/recover" exact component={Recover} />
			<Route path="/coach-subscription" exact component={CoachSubscription} />
			<Route
				path="/coach-subscription-settings"
				exact
				component={CoachSubscriptionSettings}
			/>
			<Route path="/off-season" exact component={OffSeason} />
			<PrivateRoute exact path="/account" component={Account} />
			<PrivateRoute path="/update-password" component={UpdatePassword} />
			<Route path="/contact-us" component={ContactUs} />
			<Route path="/terms-conditions" component={Terms} exact />
			<PrivateRoute path="/game-selection" exect component={GameSelection} />
			<PrivateRoute
				path="/game-selection-favourite-redirect"
				exect
				component={GameSelectionFavouriteRedirect}
			/>
			<PrivateRoute path="/communications" exect component={Communications} />
			{/* <PrivateRoute path="/social-connections" exect component={SocialConnections} /> */}

			{/* Classic Routes */}
			<Route path="/classic" exact render={() => <Redirect to="/classic/team" />} />
			<PrivateRoute path="/classic/team/user/:team_id" exact component={UserTeam} />
			<PrivateRoute path="/classic/team/:round_id?" exact component={MyTeam} />
			<PrivateRoute
				path="/classic/advanced-trades/:player_id?/:side?"
				exact
				component={AdvancedTrades}
			/>
			<PrivateRoute 
				path="/classic/edit-advanced-trades/:trade_id"
				exact
				component={EditAdvancedTrades}
			/>
			{/* REMOVED IN https://geniussports.atlassian.net/browse/F2P1-38900 */}
			{/* <PrivateRoute 
				path="/link-ultimate-footy"
				exact
				component={UFLinkingPage}
			/> */}
			{/* <PrivateRoute 
				path="/uf-password-reset"
				exact
				component={UFPWResetPage}
			/> */}
			<PrivateRoute
				path="/classic/leagues/create/:regenerate_league_id?"
				exact
				component={ClassicLeaguesCreate}
			/>
			<PrivateRoute
				path="/classic/league/:league_id"
				exact
				component={ClassicLeagueOverview}
			/>
			<PrivateRoute
				path="/classic/league/:league_id/invite"
				exact
				component={ClassicLeaguesInvite}
			/>
			<PrivateRoute
				path="/classic/league/:league_id/teams"
				exact
				component={ClassicLeagueMembers}
			/>
			<PrivateRoute
				path="/classic/league/:league_id/invite/new"
				exact
				render={props => <ClassicLeaguesInvite {...props} new_league={true} />}
			/>
			<PrivateRoute
				path="/classic/leagues/join/:code?"
				exact
				component={ClassicLeaguesJoin}
			/>
			<PrivateRoute
				path="/classic/leagues/join/search/:search"
				component={ClassicLeaguesJoin}
			/>
			<PrivateRoute
				path="/classic/league/:league_id/settings"
				exact
				component={ClassicLeagueSettings}
			/>
			<PrivateRoute
				path="/classic/league/:league_id/fixtures"
				exact
				component={ClassicLeagueFixtures}
			/>
			<PrivateRoute
				path="/classic/league/:league_id/ladder"
				exact
				component={ClassicLeagueLadder}
			/>
			<PrivateRoute
				path="/classic/league/:league_id/team-breakdowns"
				exact
				component={ClassicLeagueTeamBreakdowns}
			/>
			<PrivateRoute path={ClassicMatchUpPath} exact component={ClassicMatchUp} />
			<PrivateRoute path={ClassicMatchUpPathWithRound} exact component={ClassicMatchUp} />
			<PrivateRoute path={ClassicMatchUpPathDirect} exact component={ClassicMatchUp} />
			<PrivateRoute
				path="/classic/league/:league_id/about"
				exact
				component={ClassicLeagueAbout}
			/>
			<PrivateRoute path="/classic/player/news" exact component={PlayerNews} />
			<PrivateRoute path="/classic/player/team-of-round" exact component={TeamOfRound} />
			<PrivateRoute
				path="/classic/player/team-of-round/:round_id"
				exact
				component={TeamOfRound}
			/>

			<PrivateRoute path="/classic/leagues" exact component={MyClassicLeagues} />
			<Route path="/classic/stats-centre" exact component={StatsCentre} />
			<Route path="/classic/stats-centre/compare" exact component={ClassicComparePlayers} />
			<Route path="/classic/stats-centre/:player_slug" exact component={StatsCentre} />
			<Route path="/classic/player/:player_slug" exact component={PlayerClassic} />
			<Route
				path="/classic/stats-centre/compare/:player_id"
				exact
				component={ClassicComparePlayers}
			/>
			<PrivateRoute path="/classic/rankings" exact component={Rankings} />

			<PrivateRoute path="/classic/afl-matchups" exact component={ClassicMatchCentre} />
			<PrivateRoute
				path="/classic/afl-matchups/:match_id"
				exact
				component={ClassicMatchCentre}
			/>
			<PrivateRoute
				path="/account/:section"
				exact
				render={props => <Account {...props} />}
			/>
			
			<Route
				path="/classic/help/:section"
				exact
				render={props => <Help {...props} is_classic={true} />}
			/>
			<Route
				path="/fantasy/team/rankings"
				exact
				component={RedirectToHome}
			/>
			{/* Draft Routes */}
			{IS_HIDE_DRAFT ? <React.Fragment>
				<Route path="/draft" exact component={RedirectToHome} />
				<Route path="/draft/:all" exact component={RedirectToHome} />
			</React.Fragment> :
				<React.Fragment>
					<Route path="/draft/league/:league_id" component={RedirectOnInvalidDraftLeague} />
					<Route path="/draft" exact component={DraftLeagues} />
					<Route path="/draft/leagues" exact component={DraftLeagues} />
					<PrivateRoute path="/draft/leagues/join/:code?" exact component={DraftLeaguesJoin} />
					<PrivateRoute path="/draft/leagues/join/search/:search" component={DraftLeaguesJoin} />
					<PrivateRoute
						path="/draft/leagues/create/:regenerate_league_id?"
						exact
						component={DraftLeagueCreate}
					/>
					<PrivateRoute
						path="/draft/leagues/create/uf/:regenerate_league_id?"
						exact
						component={DraftLeagueCreateUF}
					/>
					<PrivateRoute path="/draft/league/:league_id" exact component={DraftLeagueOverview} />
					<PrivateRoute
						path="/draft/league/:league_id/about"
						exact
						component={DraftLeagueAbout}
					/>
					<PrivateRoute
						path="/draft/league/:league_id/invite"
						exact
						component={DraftLeagueInvite}
					/>
					<PrivateRoute
						path="/draft/league/:league_id/invite/new"
						exact
						render={
							props => <DraftLeagueInvite 
								{...props} 
								new_league={true} 
							/>}
					/>
					<PrivateRoute 
						path="/draft/league/:league_id/history" 
						exact component={LeagueHistory} />
					<PrivateRoute
						path="/draft/league/:league_id/settings"
						exact
						component={DraftLeagueSettings}
					/>
					<PrivateRoute
						path="/draft/league/:league_id/team-name/:from?"
						exact
						component={LeagueNameChange}
					/>
					<PrivateRoute
						path="/draft/league/:league_id/edit-picks"
						exact
						component={DraftLeagueEditPicks}
					/>
					<PrivateRoute
						path="/draft/league/:league_id/teams"
						exact
						component={DraftLeagueTeams}
					/>
					<PrivateRoute
						path="/draft/league/:league_id/edit-teams"
						exact
						component={DraftLeagueEditTeams}
					/>
					<PrivateRoute
						path="/draft/league/:league_id/edit-scores"
						exact
						component={DraftLeagueEditScores}
					/>
					<PrivateRoute
						path="/draft/league/:league_id/draft-order"
						exact
						component={DraftLeagueOrder}
					/>
					<PrivateRoute
						path="/draft/league/:league_id/remove-league"
						exact
						component={DraftLeagueRemove}
					/>
					<PrivateRoute
						path="/draft/league/:league_id/fixtures"
						exact
						component={DraftLeagueFixtures}
					/>
					<PrivateRoute
						path="/draft/league/:league_id/ladder"
						exact
						component={DraftLeagueLadder}
					/>
					<PrivateRoute
						path="/draft/league/:league_id/team-swap"
						exact
						component={TeamSwap}
					/>
					<PrivateRoute
						path="/draft/league/:league_id/team-breakdowns"
						exact
						component={DraftLeagueTeamBreakdowns}
					/>
					<PrivateRoute
						path="/draft/league/:league_id/transactions/:history?"
						exact
						component={DraftTransactions}
					/>
					<PrivateRoute path={DraftMatchUpPath} exact component={DraftMatchUp} />
					<PrivateRoute path={DraftMatchUpPathWithRound} exact component={DraftMatchUp} />
					<PrivateRoute path={DraftMatchUpPathDirect} exact component={DraftMatchUp} />
					<PrivateRoute
						path="/draft/league/:league_id/afl-matchups"
						exact
						component={DraftMatchCentre}
					/>
					<PrivateRoute
						path="/draft/league/:league_id/afl-matchups/:match_id"
						exact
						component={DraftMatchCentre}
					/>
					<PrivateRoute path="/draft/league/:league_id/pre-draft" exact component={PreDraft} />
					{!IS_DISABLE_LIVE_DRAFT &&
						<PrivateRoute 
							path="/draft/league/:league_id/live-draft" exact component={LiveDraft} 
						/>
					}
					<PrivateRoute
						path={"/draft/league/:league_id/team/:team_id?/:round_id?"}
						exact
						component={DraftTeam}
					/>
					<PrivateRoute
						path="/draft/league/:league_id/players/compare/"
						exact
						component={DraftComparePlayers}
					/>
					<PrivateRoute
						path="/draft/league/:league_id/players/compare/:player_id"
						exact
						component={DraftComparePlayers}
					/>
					<PrivateRoute
						path="/draft/league/:league_id/player/:player_slug"
						exact
						component={DraftPlayer}
					/>
					<PrivateRoute path="/draft/league/:league_id/players" exact component={Players} />
					<PrivateRoute
						path="/draft/league/:league_id/players/:action"
						exact
						component={Players}
					/>
					<PrivateRoute
						path="/draft/league/:league_id/players/:player_id/:type"
						exact
						component={Players}
					/>
					<Route
						path="/draft/help/:section"
						exact
						render={props => <Help {...props} is_classic={false} />}
					/>
					<PrivateRoute
						path={comingSoonDraftPath}
						exact
						component={ComingSoon}
					/>
				</React.Fragment>
			}
			<Route component={NoMatch} />
		</Switch>
	</React.Fragment>
);

class App extends React.Component<Props> {
	componentDidMount() {
		const {setPositions} = this.props;
		setPositions(positions);
	}

	render() {
		return (
			<AppErrorBoundary>
				<Router>
					<FetchUserIfAuthenicated>
						<GtmPageViewDataLayer>
							<main>
								<Header />
								<GlobalRedirect />
								{OFF_SEASON ? <OffSeasonRoutes /> : <InSeasonRoutes />}
							</main>
						</GtmPageViewDataLayer>
					</FetchUserIfAuthenicated>
				</Router>
			</AppErrorBoundary>
		);
	}
}

const mapDispatchToProps = {
	setPositions: actions.setPositions,
};

export default connect(null, mapDispatchToProps)(App);