// @flow
import { bindAll, isEmpty, range, xor, toInteger, get } from "lodash";
import numeral from "numeral";
import * as React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import colors from "../../assets/css/colors";
import { below } from "../../assets/css/media";
import Trophy from "../../components/Icons/Trophy";

import { fetchUserHistory } from "../../modules/actions";
import {TUser} from "../../modules/types";
import { currencyFormat } from "../../utils/currencyFormat";
import { isMobile } from "../../utils/isMobile";

const SEASON_YEAR: string = process.env.REACT_APP_SEASON_YEAR || "";
const YEARS_RANGE = range(2014, SEASON_YEAR).reverse();

const titles = {
	team: {
		desktop: {
			total_score: "Total Score",
			best_score: "Highest Score",
			worst_score: "Lowest Score",
			team_value: "Team Value",
			best_rank: "Highest Ranking",
			overall_rank: "Overall Ranking",
			final_outcome: "Final Outcome"
		},
		mobile: {
			total_score: "Total",
			best_score: "High",
			worst_score: "Low",
			team_value: "Value",
			best_rank: "High Rank",
			overall_rank: "Overall",
			final_outcome: "Final"
		}
	},
	league: {
		desktop: {
			best_rank: "Highest Ranking",
			overall_rank: "Overall Ranking",
			final_outcome: "Final Outcome"
		},
		mobile: {
			best_rank: "High Rank",
			overall_rank: "Overall",
			final_outcome: "Final"
		}
	}
};


const HistorySection = styled.div`
	margin-bottom: 20px;
`;
const Title = styled.div`
	margin-bottom: 20px;
	color: #1D4073;
	font-family: TitilliumUpright;
	font-size: 24px;
	line-height: 29px;
	${below.desktop`
		font-size: 16px;
	`}
`;

const TitleRow = styled.div`
	height: 30px;
	padding: 0px 12px;
	text-align: left;
	font-family: SourceSansPro;
	font-size: 14px;
	color: ${colors.primary.primary};
	font-weight: 700;
	line-height: 20px;
	background-color: #F0F3F7;
	border: 1px solid #CAD2D8;
	display: flex;
	align-items: center;
	justify-content: ${({isWinner}) => isWinner ? "space-between" : "flex-start"};
	svg{
		width: 24px;
		height: 24px;
		path{
			fill: #496CA0;
		}
	}
`;

const ContentWrapper = styled.div`
	width: 100%;
	display: flex;
`;

const ContentRow = styled.div`
	width: 50%;
	height: 30px;
	padding: 0px 12px;
	text-align: left;
	font-family: SourceSansPro;
	font-size: 14px;
	color: ${colors.primary.primary};
	font-weight: 400;
	line-height: 20px;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	border: 1px solid #CAD2D8;
	text-transform: capitalize;
	span{
		${({salary_change}) => salary_change && `
			color: ${salary_change};
		` }
	}
`;

const SectionTitle = styled.div`
	color: #FFFFFF;
	font-size: 20px;
	line-height: 20px;
	font-family: SourceSansPro;
	font-weight: 700;
	height: 60px;
	display: flex;
	align-items: center;
	color: ${colors.primary.primary};
	padding: 0 10px;
`;

const NoYearTitle = styled.div`
	font-size: 14px;
	font-weight: 400;
	line-height: 20px;
	text-align: center;
	color: #7F7F7F;
	height: 50px;
	display: flex;
	align-items: center;
	font-family: SourceSansPro;
	justify-content: center;
	margin-bottom: 20px;
	background: #F0F3F7;
`;
const TeamInfoWrapper = styled.div`
	padding: 0;
	display: flex;
	flex-direction: column;
	width: 100%;
`;

type Props = {
	fetchUserHistory: typeof fetchUserHistory,
	history: Object,
	user: TUser
};

type State = {
	is_mobile: boolean,
	open_classic_leagues: Array<number>,
	open_draft_leagues: Array<number>,
	game_type: string,
}

class LeaguesHistoryComp extends React.Component<Props, State> {
	constructor(props, state) {
		super(props, state);

		bindAll(this, [
			"getClassicHistory",
			"teamInfo",
			"resize",
			"toggleLeagues",
			"showLeagues",
			"handleTypeChange"
		]);
	}

	state = {
		is_mobile: false,
		open_classic_leagues: [],
		open_draft_leagues: [],
		game_type: "classic"
	};

	componentDidMount() {
		window.addEventListener("resize", this.resize);
		this.resize();
		this.props.fetchUserHistory();
	}
	componentWillUnmount() {
		window.removeEventListener("resize", this.resize);
	}

	get history() {
		const { history } = this.props;
		// const { game_type } = this.state;


		if (isEmpty(history)){
			return null;
		}
		return (
			<div>
				<Title>
					Classic Summary:
				</Title>

				{YEARS_RANGE.map(this.getClassicHistory)}

			</div>
		);
	}

	teamInfo({ team, leagues }, year){
		const { is_mobile } = this.state;
		
		const title_obj = titles.team[is_mobile ? "mobile" : "desktop"];
		const salary_change = team.team_value - team.salary_cap;
		const salaryChangeColor = () => {
			if(salary_change !== 0){
				return salary_change > 0 ? colors.success : colors.errorColor;
			}
			return colors.primary.primary;
		};


		return(
			<TeamInfoWrapper>
				<TitleRow>Overall</TitleRow>
				<ContentWrapper>
					<ContentRow>
						{title_obj.total_score}
					</ContentRow>
					<ContentRow>
						{numeral(team.total_score).format("0,0")} 
						{" "} 
						{`(Average ${team.avg_score})`}
					</ContentRow>
				</ContentWrapper>
				<ContentWrapper>
					<ContentRow>
						{title_obj.best_score}
					</ContentRow>
					<ContentRow>
						{numeral(team.best_score).format("0,0")}
						{" "} 
						{`(Round ${team.best_score_rd})`}
					</ContentRow>
				</ContentWrapper>
				<ContentWrapper>
					<ContentRow>
						{title_obj.worst_score}
					</ContentRow>
					<ContentRow>
						{numeral(team.worst_score).format("0,0")}
						{" "} 
						{`(Round ${team.worst_score_rd})`}
					</ContentRow>
				</ContentWrapper>
				<ContentWrapper>
					<ContentRow>
						{title_obj.team_value}
					</ContentRow>
					<ContentRow salary_change={salaryChangeColor()}>
						{currencyFormat({ input: team.team_value })}
						{" "} (
						<span>
							{currencyFormat(
								{ input: salary_change, currency: "", show_sign: true }
							)}
						</span>
						)
							
					</ContentRow>
				</ContentWrapper>
				<ContentWrapper>
					<ContentRow>
						{title_obj.best_rank}
					</ContentRow>
					<ContentRow>
						{numeral(team.best_rank).format("0,0")}
						{" "} 
						{`(Round ${team.best_rank_rd})`}
					</ContentRow>
				</ContentWrapper>
				<ContentWrapper>
					<ContentRow>
						{title_obj.overall_rank}
					</ContentRow>
					<ContentRow>
						{numeral(team.overall_rank).format("0,0")}
						{" / "} 
						{team.overall_rank_of}
					</ContentRow>
				</ContentWrapper>
				{this.showLeagues(leagues)}
			</TeamInfoWrapper>
		);
	}

	showLeagues(leagues) {
		const { is_mobile } = this.state;
		const { user } = this.props;
		const title_obj = titles.league[is_mobile ? "mobile" : "desktop"];
		return(
			<React.Fragment>
				{leagues.map(league => {
					const isLeagueOpen = get(league, "type", "") === "OPEN";
					const isWinner = get(league, "winning_user_id", 0) === user.id;
					const leagueName = 
						league.league_name === "Overall" 
							? "Overall League" : league.league_name;
					const finalOutcomeText = () => {
						if(isLeagueOpen){
							return "";
						}
						if(league.final_round === "Grand Final"){
							const resultText = isWinner 
								? "Grand Final Winner" 
								: "Finalist (2nd Place)";
							return `${resultText}`;
						} 
						if(league.final_round === "Did not make finals"){
							return league.final_round;
						}
						if(league.final_round === "None"){
							return "Finals not applicable";
						}
						return `Eliminated in ${league.final_round} Final`;
					};
					return(
						<React.Fragment>
							<TitleRow isWinner={isWinner}>
								{leagueName}
								{isWinner && <Trophy />}
							</TitleRow>
							<ContentWrapper>
								<ContentRow>
									{title_obj.best_rank}
								</ContentRow>
								<ContentRow>
									{numeral(league.best_rank).format("0,0")}
									{" "} 
									{`(Round ${league.best_rank_rd})`}
								</ContentRow>
							</ContentWrapper>
							<ContentWrapper>
								<ContentRow>
									{title_obj.overall_rank}
								</ContentRow>
								<ContentRow>
									{numeral(league.rank).format("0,0")}
									{" / "} 
									{league.num_teams}
								</ContentRow>
							</ContentWrapper>
							{!isLeagueOpen &&
								<ContentWrapper>
									<ContentRow>
										{title_obj.final_outcome}
									</ContentRow>
									<ContentRow>
										{finalOutcomeText()}
									</ContentRow>
								</ContentWrapper>
							}
						</React.Fragment>
					);
				})}
			</React.Fragment>
		);
	}

	getClassicHistory(year) {
		const { history } = this.props;
		const { classic } = history;

		const year_data = classic[year];

		if (!year_data) {
			return this.noYear(year);
		}

		return (
			<HistorySection key={year}>
				<SectionTitle>
					{year} Summary
				</SectionTitle>
				{this.teamInfo(year_data, year)}
			</HistorySection>
		);
	}

	toggleLeagues({ currentTarget }) {
		const { year, type } = currentTarget.dataset;
		this.setState({
			[type]: xor(this.state[type], [toInteger(year)])
		});
	}

	noYear(year) {
		const { game_type } = this.state;
		const handledString = game_type.charAt(0).toUpperCase() + game_type.slice(1);
		return (
			<NoYearTitle key={year}>
				You did not play {handledString} in {year}
			</NoYearTitle>
		);
	}

	resize() {
		this.setState({
			is_mobile: isMobile()
		});
	}

	handleTypeChange() {
		const { game_type } = this.state;
		const changeTo = game_type === "classic" ? "draft" : "classic";
		this.setState({ game_type: changeTo });
	}

	render() {
		// const { game_type } = this.state;
		return (
			<React.Fragment>
				{/* <SwitchWrapper>
					<SwitchButton 
						onClick={this.handleTypeChange} 
						isActive={game_type === "classic"}>
						Classic
					</SwitchButton>
					<SwitchButton 
						onClick={this.handleTypeChange} 
						isActive={game_type === "draft"}>
						Draft
					</SwitchButton>
				</SwitchWrapper> */}

				{this.history}

			</React.Fragment>
		);
	}
}

const mapStateToProps = state => ({
	history: state.user.history,
	user: state.user.data
});

const mapDispatchToProps = {
	fetchUserHistory
};

export const LeaguesHistory = connect(
	mapStateToProps,
	mapDispatchToProps
)(LeaguesHistoryComp);

export default LeaguesHistory;