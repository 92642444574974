// @flow
import React from "react";
import { connect } from "react-redux";
import { withRouter, Link, Redirect } from "react-router-dom";
import styled from "styled-components";
import * as _ from "lodash";
import * as actions from "../modules/actions";
import {
	AdsContainer,
	Ad,
	Tabs,
	TabItem,
	Footer,
	PageTitle,
	PageContentWrapper,
	TwoColumnLayout,
	StandardSidebar,
	ButtonPrimary,
} from "../components";
// import { IS_PRIZES_CLOSED } from '../modules/constants/index';
import { below } from "../assets/css/media";
import ArrowRight from "../components/Icons/ArrowRight";
import ArrowLeft from "../components/Icons/ArrowLeft";
import { isMobile } from "../utils";
import type { TSection } from "./help/sections";
import { sections } from "./account/sections";
import ContactUs from "./ContactUs";
import ClassicSeasonHistory from "./account/ClassicSeasonHistory";
import AccountContent from "./account/AccountContent";
import ChangePasswordSection from "./account/ChangePassword";
import Communications from "./Communications";
// import SocialConnectionsContent from "./account/SocialConnections";
import LeaguesHistory from "./account/LeaguesHistory";
import TermsConditions from "./account/TermsConditions";
import FantasyCoachTerms from "./FantasyCoachTerms";
import CoachSubscriptionContent from "./account/CoachSubscription";



const TabLink = styled(Link)`
	text-decoration: none;
  	margin-right: 20px;
  	width: fit-content;
	white-space: nowrap;
  	display: flex;
  ${below.phone`
    justify-content: space-around;
		margin-right: 0px;
		width: 100%;
    a{
      width: fit-content;
      margin: 0px;

    }
  `}
`;

const ContentDiv = styled.div`
	width: 100%;
`;

const StyledTabs = styled(Tabs)`
  	border-bottom: 1px solid #F0F3F7;
	margin-bottom: 20px;
	overflow-x: auto;
	scroll-behavior: smooth;
	&::-webkit-scrollbar {
  		display: none;
	}	
	-ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none; 
`;

const StyledTabItem = styled(TabItem)`
  	width: fit-content;
	padding: 0 10px;
  	display: inline;
  	height: 25px;
  	border-top: 2px solid transparent;
	border-bottom: 2px solid transparent;
	${below.tablet`
		width: auto;
	`}
`;


const ContentContainer = styled.div`
	${below.tablet`
		padding: 0 5px;
	`}
`;


const TabsWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-start;
	width: 100%;
	position: relative;
`;

const TabFadeOutRight = styled.div`
	width: 20px;
	height: 25px;
	top: 0px;
	position: absolute;
	right: 0px;
	background: linear-gradient(90deg, rgba(255,255,255,0.2) 0%, rgba(255,255,255,1) 100%);
	${below.phone`
    display: none;
  `}
`;

const TabFadeOutLeft = styled(TabFadeOutRight)`
	right: unset;
	left: 0px;
	background: linear-gradient(270deg, rgba(255,255,255,0.2) 0%, rgba(255,255,255,1) 100%);
`;

const TabControlButton = styled.button`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 20px;
	height: 25px;
	position: absolute;
	right: 5px;
	z-index: 1;
	top: -4px;
	background: none;
	padding: 0;
	outline: none;
	border: none;
	svg{
		width: 90%;
		height: 100%;
	}
	${below.phone`
    display: none;
  `}
`;

const TabControlButtonLeft = styled(TabControlButton)`
	right: unset;
	left: 10px;
`;

type Props = {
	match: { params: { section: string }},
	location: Object,
	contact: Object,
	contactUsClear: Function,
	contactUs: Function,
	logout: actions.logout,
}

type State = {
	logout: boolean,
	scrollLast: string,
	scrollValue: number,
}

class Account extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props);
		this.activeTab = React.createRef();
		this.contentRef = React.createRef();
		_.bindAll(this, [
			"sectionTab",
			"handleModalClose",
			"handleLogout",
			"handleScrollValueChange"
		]);
	}
	

	state = {
		logout: false,
		scrollLast: "",
		scrollValue: 0,
	};

	componentDidMount() {
		if(this.contentRef.current){
			this.contentRef.current.scrollTop = 0;
		}
	}

	tabRef = React.createRef();

	get tabRefElement() {
		return this.tabRef.current;
	}

	handleTabButtonClick(direction: string, e) {
		e.stopPropagation();
		const scrollAmount = direction === "left" ? 0 : 400;
		if(this.tabRefElement){
			this.tabRefElement.scrollLeft = scrollAmount;
		}
		this.setState({
			scrollLast: direction
		});
	}

	handleScrollValueChange(e) {
		e.stopPropagation();
		const currentScrollValue = this.tabRef.current ? this.tabRef.current.scrollLeft : 0;
		this.setState({ scrollValue: currentScrollValue });
	}

	activeTab: { current: null | HTMLLIElement };

	handleScroll=()=>{
		
		setTimeout(()=>{
			if(this.activeTab.current !== null){
				this.activeTab.current.scrollIntoView({
					behavior: "smooth",
					block: "start",
					inline: "start"
				});
			}
		}, 100);
		
	};

	
	contentRef: { current: null | HTMLDivElement };

	sectionTab(tab_section: TSection) {
		const { match } = this.props;
		const { section } = match.params;
		const isActive = section === tab_section.id;
		return (
			<TabLink
				key={tab_section.id}
				to={`/account/${tab_section.id}`}
			>
				<StyledTabItem 
					ref={isActive ? this.activeTab : ""} 
					className={isActive ? "active-tab" : ""}
					onClick={this.handleScroll}>
					{tab_section.name}
				</StyledTabItem>
			</TabLink>
		);
	}

	getCurrentSection(section: string) {
		return _.find(sections, { id: section });
	}

	get content_width() {
		if(isMobile()){
			return 1100;
			// return window.innerWidth;
		}
		if(this.contentRef.current){
			return this.contentRef.current.offsetWidth;
		}
		return 920;
	}


	handleModalClose() {
		const { contactUsClear } = this.props;
		contactUsClear();
	}

	handleLogout() {
		this.setState({
			logout: true,
		});
	}

	logoutButton() {
		return <ButtonPrimary onClick={this.handleLogout}> Logout </ButtonPrimary>;
	}

	contentHandler() {
		const { match } = this.props;
		const { section } = match.params;
		const contentObject = {
			"my-account": <AccountContent />,
			"change-password": <ChangePasswordSection />,
			"communications": <Communications />,
			// "social-connections": <SocialConnectionsContent />,
			"coach-subscription": 	<CoachSubscriptionContent />,
			// "pro-subscription": <ProSubscriptionContent />,
			"your-history": <LeaguesHistory />,
			"contact-us": <ContactUs from_account={true} />,
			"terms-and-conditions": <TermsConditions />,
			"fantasy-coach-terms":  <FantasyCoachTerms />,
			"classic-history": <ClassicSeasonHistory content_width={this.content_width}/>,
			"logout": this.logoutButton(),
		};
		return contentObject[section] || <AccountContent />;
		
	}

	renderTabButtonContent() {
		const currentScrollValue = this.tabRef.current ? this.tabRef.current.scrollLeft : 0;

		const showLeft = currentScrollValue > 0;
		const showRight = currentScrollValue < 261;
		return (
			<React.Fragment>
				{showLeft && 
					<React.Fragment>
						<TabControlButtonLeft onClick={e => this.handleTabButtonClick("left", e)}>
							<ArrowLeft />
						</TabControlButtonLeft>
						<TabFadeOutLeft />
					</React.Fragment>
				}
				{showRight && 
					<React.Fragment>
						<TabFadeOutRight />
						<TabControlButton 
							onClick={e => this.handleTabButtonClick("right", e)}
						>
							<ArrowRight />
						</TabControlButton>
					</React.Fragment>
				}
			</React.Fragment>
		);
	}

	render() {
		const { logout } = this.state;
		// if (section === 'prizes' && IS_PRIZES_CLOSED) {
		// 	return <HOCComingSoon />;
		// }
	

		if(logout){
			return <Redirect to="/logout" />;
		}

		return (
			<React.Fragment>
				<AdsContainer>
					<Ad />
				</AdsContainer>
				<PageContentWrapper>
					<PageTitle>
							Account
					</PageTitle>
					<TwoColumnLayout>
						<ContentContainer>
							<ContentDiv ref={this.contentRef}>
								{" "}
							</ContentDiv>
							<TabsWrapper>
								<StyledTabs 
									ref={this.tabRef} 
									onScroll={e=>this.handleScrollValueChange(e)}
								>
									{_.map(sections, this.sectionTab)}
									{this.renderTabButtonContent()}								
								</StyledTabs>
							</TabsWrapper>
							{this.contentHandler()}
							
						</ContentContainer>
						<StandardSidebar />
					</TwoColumnLayout>
				</PageContentWrapper>
				<Footer />
			</React.Fragment>
		);
	}
};

const mapStateToProps = state => ({
	contact: state.contact,
});

const mapDispatchToProps = {
	contactUs:actions.contactUs,
	contactUsClear:actions.contactUsClear,
	logout: actions.logout,
};

export const AccountComp = connect(
	mapStateToProps,
	mapDispatchToProps
)(Account);

export default withRouter(Account);